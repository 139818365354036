
    <template>
    <div>
    <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else>
            <form @submit.prevent="add_item_images" autocomplete="off">
                <v-layout row wrap>
            <v-flex xs12 lg2 xl2 md3 sm4>
            <v-select class="mx-1" clearable rounded filled :items="items" v-model="item_images.item_id" outlined  item-text="item_id"
                            item-value="item_id" :return-object="false" :label="$store.getters.lang.data.item_id">
                        </v-select>
                        </v-flex>
            
        <v-flex xs12 lg2 xl2 md3 sm4>
        <v-text-field v-model="item_images.item_image_path" type="text" :label="$store.getters.lang.data.item_image_path" outlined
            class="mx-1"  required rounded>
        </v-text-field>
    </v-flex>


    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn rounded color="primary" type="submit" x-large >{{$store.getters.lang.data.add_btn}} item_images</v-btn>
                    </v-flex>
                </v-layout>
            </form>
            <v-layout row wrap mt-5>
                <v-flex xs12>
                    <v-card>
                        <v-card-title primary-title>
                        {{$store.getters.lang.data.item_images}}
                            <v-spacer></v-spacer>
                            <v-text-field autocomplete="off" v-model="search" append-icon="fas fa-search">
                            </v-text-field>
                        </v-card-title>
                        <v-card-text>
                            <v-data-table :headers="headers" :search="search" :items="rows" class="elevation-0"
                                item-key="item_image_id">
                                <template v-slot:[`item.item_image_id`]="{ item }">
                                    <div>
                                        <v-btn icon color="error" :disabled="$store.state.auth.user.admin_role != 'admin'" class="mx-1" @click="select_item_images(item)" rounded>
                                            <v-icon>mdi-delete-outline</v-icon>
                                        </v-btn>
                                        <v-btn :to="'/item_images-list/'+item.item_image_id" icon color="secondary" class="mx-1"
                                            rounded>
                                            <v-icon>mdi-pencil-outline</v-icon>
                                        </v-btn>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
        <v-dialog v-model="delete_dialog" persistent max-width="290">
            <v-card>
                <v-card-title>
                    {{$store.getters.lang.data.delete_question}}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="elevation-0" @click="delete_dialog = false">
                    {{$store.getters.lang.data.cancel_btn}}
                    </v-btn>
                    <v-btn color="green darken-1" text @click="delete_item_images(selected_item_images.item_image_id)">
                    {{$store.getters.lang.data.yes_btn}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.value" :color="snackbar.color">
            {{snackbar.text}}
        </v-snackbar>
    </div>
</template>
<script>
    import controller from './../../controller/item_images'
    export default {
        data() {
            return {
                item_images: {},
                search: '',
                loading : true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                selected_item_images: {},
                delete_dialog: false,
                headers: [
                    
        {
            text: this.$store.getters.lang.data.item_image_id,
            align: 'start',
            sortable: true,
            value: 'item_image_id',
        },


        {
            text: this.$store.getters.lang.data.item_id,
            align: 'start',
            sortable: true,
            value: 'item_id',
        },


        {
            text: this.$store.getters.lang.data.item_image_path,
            align: 'start',
            sortable: true,
            value: 'item_image_path',
        },


                ],
            }
        },
        computed: {
            rows(){
                return this.$store.getters.item_images
            },
            
                    items (){
                        return this.$store.getters.items
                    },
                    
            
        },
        mounted(){
            this.read_item_images();
        },
        methods: {
            add_item_images() {
                controller.add_item_images(this.item_images).then(r => {
                    if (r.data) {
                        this.item_images = {}
                        this.$store.state.item_images.push(
                            r.data.new_data
                        )
                        this.snackbar = {
                            value: true,
                            text: 'item_images Added',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to add item_images',
                            color: 'error'
                        }
                    }
                })
            },
            delete_item_images(item_image_id) {
                controller.delete_item_images(item_image_id).then(r => {
                    this.delete_dialog = false
                    if (r.data) {
                        this.$store.state.item_images = this.$store.state.item_images.filter(f =>{
                            return f.item_image_id != item_image_id
                        })
                        this.snackbar = {
                            value: true,
                            text: 'item_images Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },
            read_item_images() {
                this.loading = true
                controller.get_item_images().then(r => {
                    // this.rows = r.data.rows
                    this.loading = false
                })
            },
            select_item_images(i) {
                this.selected_item_images = i
                this.delete_dialog = true
            },
        },
    }
</script>
